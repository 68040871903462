import { Directive, HostListener, ElementRef, Renderer2 } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
@Directive({
  selector: '[tryCurrencyFormat]'
})
export class TryCurrencyFormatDirective {

  constructor(
    private el: ElementRef,
     private renderer: Renderer2, 
     private currencyPipe: CurrencyPipe
  ) { }
  @HostListener('input', ['$event.target.value'])
  onInput(inputValue: string) {
    const numericValue = parseFloat(inputValue.replace(/[^\d.-]/g, '')); 
    const formattedValue = this.currencyPipe.transform(numericValue, 'TRY', 'symbol', '1.2-2'); 
    this.renderer.setProperty(this.el.nativeElement, 'value', formattedValue); 
  }
}
