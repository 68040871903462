import { Injectable } from '@angular/core';
import { BaseService, HttpClientService } from './base.service';
import { Keys, OrderOptions } from '../constants/keys';
import { Observable } from 'rxjs';
import { BaseResponseWrapper, PaginatedResponseWrapperOvi } from '../models/api-response-wrappers';
import { CreditCardRequest, CreditCardResponse } from '../models/credit-card';

const Endpoints = {
   Controller: 'users/me/credit-cards',
   CreditCardsList: 'users/me/credit-cards-list',
   DeleteCreditCard: 'users/me/credit-cards/{id}',
   UpdateCreditCard: 'users/me/credit-cards-maincard/{id}'
};

@Injectable()
export class CreditCardService extends BaseService<CreditCardRequest, CreditCardResponse> {
   constructor(httpClient: HttpClientService) {
      super(httpClient, Endpoints.Controller);
   }

   getCreditCards(){
      const userId = localStorage.getItem(Keys.USER_ID);
      const json = {
         userId: userId,
      };

      const fullPath = this.createUrlWithParams(Endpoints.CreditCardsList, json);
      return this.client.get<PaginatedResponseWrapperOvi<CreditCardResponse>>(fullPath);
   }

   createCreditCard(request: CreditCardRequest): Observable<BaseResponseWrapper<CreditCardResponse>> {
      const  userId = localStorage.getItem(Keys.USER_ID);
      const url = this.createUrlWithParams(Endpoints.Controller, { userId: userId });
      const json = {
         ...request,
      };
      return this.client.post<BaseResponseWrapper<CreditCardResponse>>(url, JSON.stringify(json));
   }

   deleteCreditCard(cardId){
      const  userId = localStorage.getItem(Keys.USER_ID);
      const fullPath = (this.createUrlWithParams(Endpoints.DeleteCreditCard, { userId: userId, id: cardId }));
      return this.client.delete<BaseResponseWrapper<CreditCardResponse>>(fullPath);
   }

   updateCreditCard(creditCardItemId) {
      const  userId = localStorage.getItem(Keys.USER_ID);
      const url = this.createUrlWithParams(Endpoints.UpdateCreditCard, { userId: userId,id :creditCardItemId });
      return this.client.post<BaseResponseWrapper<CreditCardResponse>>(url);
   }
}