export enum TransactionStatusEnum {
    CanceledByAdmin = 'CanceledByAdmin',
    Canceled = 'Canceled',
    Pending = 'Pending',
    InProgress = 'InProgress',
    Completed = 'Completed',
    Delayed = 'Delayed',
    Unanswered = 'Unanswered',
    Returned = 'Returned',
    Error = 'Error',
    Rejected = 'Rejected'
}