import { NgModule } from '@angular/core';
import { TextStarMaskPipe } from './text-star-mask.pipe';

@NgModule({
    declarations: [
        TextStarMaskPipe
    ],
    exports:[
        TextStarMaskPipe
    ]
})

export class PipesModule { }
