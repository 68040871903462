import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Keys } from '../constants/keys';
import { UserResponse } from '../models/user';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private router: Router,
    ) { }

    canActivate(route: ActivatedRouteSnapshot) {
        const token = localStorage.getItem(Keys.USER_TOKEN);
        const userData = localStorage.getItem(Keys.USER_DATA);

        if (token == null || userData == null) {
            this.router.navigate(['/account/login']);
            return false;
        }

        const user = <UserResponse>JSON.parse(userData);
        // logged in so return true
        const roles = route.data['roles'] as Array<string>;

        if (!roles) {
            return true;
        }

        for (const role of roles) {
            for (const userRole of user.roles) {
                if (userRole.name === role) {
                    return true;
                }
            }
        }

        this.router.navigate(['/dashboard']);
        return false;
    }
}
