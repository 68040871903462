import { City } from "./city";
import { Region } from "./region";


export class Country {
    id: number;
    name: string;
    alpha2Code: string;
    alpha3Code: string;
    numericCode: number;
    flagFileName: string | null;
    cities: City[];
    regions: Region[];
}