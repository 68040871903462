import { Injectable } from '@angular/core';
import { UserGroupResponse } from '../models/user-group';
import { BaseService, HttpClientService } from './base.service';

const Endpoints = {
   Controller: 'user-groups',
};

@Injectable()
export class UserGroupService extends BaseService<any, UserGroupResponse> {
   constructor(httpClient: HttpClientService) {
      super(httpClient, Endpoints.Controller);
   }
}