import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AccountModule } from './pages/account/account.module'
const routes: Routes = [
  {
    path: 'account',
    loadChildren: () =>
      import('./pages/account/account.module').then(x => x.AccountModule),
  },
  {
    path: '',
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
  },
  {
    path: 'standalone',
    loadChildren: () => import('./pages/standalone/standalone.module')
      .then(m => m.StandaloneModule),
  },
  { path: '**', redirectTo: '' },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
