import { Injectable } from '@angular/core';
import { BaseService, HttpClientService } from './base.service';
import { BaseResponseWrapper } from '../models/api-response-wrappers';
import { Country } from '../models/geographical/country';
import { City } from '../models/geographical/city';
import { District } from '../models/geographical/district';

const Endpoints = {
    Controller: 'geographical',
    Countries: 'geographical/list-countries',
    Regions: 'geographical/list-countries',
    Cities: 'geographical/list-cities/{countryId}',
    Districts: 'geographical/list-districts/{cityId}'
};

@Injectable()
export class GeographicalService extends BaseService<any, any> {

    constructor(httpClient: HttpClientService) {
        super(httpClient, Endpoints.Controller);
    }

    async listCountries(): Promise<Country[]> {
        const url = this.createUrlWithParams(Endpoints.Countries, {});
        const countriesResponse: BaseResponseWrapper<Country[]> = await this.client.get<BaseResponseWrapper<Country[]>>(url).toPromise()
        return countriesResponse.data;
    }

    async listCities(countryId: number): Promise<City[]> {
        const url = this.createUrlWithParams(Endpoints.Cities, { countryId });
        const cititesResponse: BaseResponseWrapper<City[]> = await this.client.get<BaseResponseWrapper<City[]>>(url).toPromise()
        return cititesResponse.data;
    }

    async listDistricts(cityId: number): Promise<District[]> {
        const url = this.createUrlWithParams(Endpoints.Districts, { cityId });
        const districtsResponse: BaseResponseWrapper<District[]> = await this.client.get<BaseResponseWrapper<District[]>>(url).toPromise()
        return districtsResponse.data;
    }
}
