import { Component } from '@angular/core';
import { BaseComponent } from '../../../shared/base.component';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  templateUrl: './footer.component.html',
})
export class FooterComponent extends BaseComponent {
  constructor(
    protected translateService: TranslateService,
  ) {
    super(translateService);
  }
}
