import { Injectable } from '@angular/core';
import { BaseService, HttpClientService } from './base.service';
import { Observable } from 'rxjs';
import { BaseResponseWrapper } from '../models/api-response-wrappers';
import { LoginRequest, RegisterRequest, ResetPasswordRequest, ChangePasswordRequest, ChangeStatusRequest, ForgotPassword } from '../models/account';
import { UserRequest, UserResponse } from '../models/user';
import { Keys } from '../constants/keys';
import { isNullOrUndefined } from 'util';

const Endpoints = {
  Controller: 'account',
  Login: 'account/login',
  Register: 'account/register',
  ChangePassword: 'account/change-password',
  Forgot_Password: 'account/forgot-password',
  Reset_Password: 'account/reset-password/{key}',
  Update_User: 'account/user-information',
  Change_Status: 'account/{id}/status',
  DebtPayment: 'users/me/debt-payment',
  GetMobileVersions: 'mobileversion/latest',
  SaveMobileVersions: 'mobileversion'
};


@Injectable()
export class AccountService extends BaseService<any, any> {

  constructor(httpClient: HttpClientService) {
    super(httpClient, Endpoints.Controller);
  }

  login(request: LoginRequest): Observable<BaseResponseWrapper<string>> {
    const url = this.createUrlWithParams(Endpoints.Login);
    return this.client.post(url, JSON.stringify(request));
  }


  debtPayment() {
    const fullPath = this.createUrlWithParams(Endpoints.DebtPayment);
    return this.client.get<BaseResponseWrapper<UserResponse>>(fullPath);
  }

  register(request: RegisterRequest): Observable<BaseResponseWrapper<any>> {
    const url = this.createUrlWithParams(Endpoints.Register);
    // TODO:add sectorId, birthDate, gender
    const json = {
      email: request.email,
      password: request.password,
      firstName: request.firstName,
      lastName: request.lastName,
      phoneNumber: request.phoneNumber,
      companyName: request.companyName,
      documentType: request.documentType,
    };

    return this.client.post(url, JSON.stringify(json));
  }

  forgotPassword(request: ForgotPassword): Observable<BaseResponseWrapper<ForgotPassword>> {
    const fullPath = this.createUrlWithParams(Endpoints.Forgot_Password);
    const json = {
      ...request
    };
    return this.client.post<BaseResponseWrapper<ForgotPassword>>(fullPath, JSON.stringify(json));
  }

  resetPassword(key: string, resetPasswordRequest: ResetPasswordRequest): any {
    const json = {
      // email: resetPasswordRequest.email,
      newPassword: resetPasswordRequest.newPassword,
      // token: resetPasswordRequest.token,
      resetKey: key
    };

    const fullPath = this.createUrlWithParams(Endpoints.Reset_Password, { key });
    return this.client.post<BaseResponseWrapper<any>>(fullPath, JSON.stringify(json));
  }

  changePassword(changePasswordRequest: ChangePasswordRequest): any {
    const fullPath = this.createUrlWithParams(Endpoints.ChangePassword);
    return this.client.post<BaseResponseWrapper<any>>(fullPath, JSON.stringify(changePasswordRequest));
  }

  updateUser(request: UserRequest): Observable<BaseResponseWrapper<UserResponse>> {
    const url = this.createUrlWithParams(Endpoints.Update_User);

    let json = {
      companyName: request.companyName,
      firstName: request.firstName,
      lastName: request.lastName,
      email: request.email,
      birthDate: request.birthDate,
      gender: request.gender,
      documentType: request.documentType,
      taxNo: request.taxNo,
      taxOffice: request.taxOffice,
      isSmsAdvertisingPermitted: request.isSmsAdvertisingPermitted,
      isEmailAdvertisingPermitted: request.isEmailAdvertisingPermitted,
      eInvoiceUsername: request.eInvoiceUsername,
      eInvoicePassword: request.eInvoicePassword,
    };

    const userAddress = {
      address: request.address,
    };

    if (!isNullOrUndefined(request.address)) {
      json = { ...json, ...userAddress };
    }

    return this.client.put<BaseResponseWrapper<UserResponse>>(url, JSON.stringify(request));
  }
  changeStatus(userId: string = localStorage.getItem(Keys.USER_ID), changeStatus: ChangeStatusRequest): Observable<BaseResponseWrapper<UserResponse>> {
    const url = this.createUrlWithParams(Endpoints.Change_Status, { id: userId });
    return this.client.put<BaseResponseWrapper<UserResponse>>(url, JSON.stringify(changeStatus));
  }

  GetPlatformBaseVersionInfoOfTheMobileApps(){
    const url = this.createUrlWithParams(Endpoints.GetMobileVersions);
    return this.client.get<BaseResponseWrapper<any>>(url);
  }

  SavePlatformBaseVersionInfoOfTheMobileApps(appPlatform: number, version: string){
    const url = this.createUrlWithParams(Endpoints.SaveMobileVersions);

    let json = {
      "appPlatform": appPlatform,
      "appVersion": version
    };
    return this.client.post<BaseResponseWrapper<any>>(url, JSON.stringify(json));
  }
}


