import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NbInputModule, NbIconModule, NbButtonModule, NbSpinnerModule } from '@nebular/theme';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { Ng2ImgMaxModule, Ng2ImgMaxService } from 'ng2-img-max';
import { TranslateModule } from '@ngx-translate/core';
import { CountryCityStateAutoComplateComponent } from './country-city-state-auto-complate/country-city-state-auto-complate.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { FormsModule } from '@angular/forms';
import { ImageZoomComponent } from './image-zoom/image-zoom.component';
@NgModule({
  declarations: [ImageUploadComponent, CountryCityStateAutoComplateComponent, ImageZoomComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    NbInputModule,
    NbIconModule,
    NbButtonModule,
    NbSpinnerModule,
    Ng2ImgMaxModule,
    AutoCompleteModule,
    FormsModule
  ],
  exports: [ImageUploadComponent, CountryCityStateAutoComplateComponent,ImageZoomComponent],
  providers: [Ng2ImgMaxService],
})
export class HelperComponentsModule { }

