import { Injectable } from '@angular/core';
import { BaseService, HttpClientService } from './base.service';
import { TaxExemption } from '../models/tax/tax-exemption';
import { BaseResponseWrapper } from '../models/api-response-wrappers';
import { TaxOffice } from '../models/tax/tax-office';

const Endpoints = {
    Controller: 'tax-office',
    Search: 'tax-office/search?term={term}'
};

@Injectable()
export class TaxOfficeService extends BaseService<any, any> {

    constructor(httpClient: HttpClientService) {
        super(httpClient, Endpoints.Controller);
    }

    async search(term:string): Promise<TaxOffice[]> {
        const fullPath = this.createUrlWithParams(Endpoints.Search, { term });
        const response : BaseResponseWrapper<TaxOffice[]> = await this.client.get<BaseResponseWrapper<TaxOffice[]>>(fullPath).toPromise();
        if (response !== null && response.data !== null)
            return response.data;
        else
            throw response.message;
    }

}
