import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { BaseService, HttpClientService } from './base.service';
import { UserResponse, UserSearchParams, UserUpdateByAdminRequest } from '../models/user';
import { OrderOptions } from '../constants/keys';
import { PaginatedResponseWrapperOvi, BaseResponseWrapper } from '../models/api-response-wrappers';
import { AgentRequest } from '../models/agent';

const Endpoints = {
   Controller: 'agents',
};

@Injectable()
export class AgentService extends BaseService<AgentRequest, UserResponse> {
   constructor(httpClient: HttpClientService) {
      super(httpClient, Endpoints.Controller);
   }
}