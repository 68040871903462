import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textStarMask'
})
export class TextStarMaskPipe implements PipeTransform {

  transform(txt: string) {
    let maskedStr = "";
    if(txt != null && txt != ""){
      for (let index = 0; index < txt.length; index++) {
        const char = txt[index];
        maskedStr += "*";
      }
    }
    return maskedStr;
  }

}
