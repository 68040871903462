import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { HttpClientService, BaseService } from './base.service';
import { ContentRequest, ContentResponse } from '../models/content';
import { BaseResponseWrapper, PaginatedResponseWrapperOvi } from '../models/api-response-wrappers';

const Endpoints = {

  Faq: 'common/faq',
  Create_Faq: 'faqs',
  Privacy_Policy: 'common/privacy-policy',
  Create_Privacy_Policy: 'privacy-policies',
  User_Agreement: 'common/user-agreement',
  Create_User_Agreement: 'user-agreements',
};

@Injectable()


export class ContentService extends BaseService<ContentRequest, ContentResponse> {

  constructor(httpClient: HttpClientService) {
    super(httpClient, Endpoints.Faq);
  }

  getFaq(): Observable<BaseResponseWrapper<ContentResponse>> {
    const fullPath = this.createUrlWithParams(Endpoints.Faq);
    return this.client.get<PaginatedResponseWrapperOvi<ContentResponse>>(fullPath);
  }

  createFaq(request: ContentRequest): Observable<BaseResponseWrapper<ContentResponse>> {
    const url = this.createUrlWithParams(Endpoints.Create_Faq);
    return this.client.post<BaseResponseWrapper<ContentResponse>>(url, JSON.stringify(request));
  }

  getPrivacyPolicy(): Observable<BaseResponseWrapper<ContentResponse>> {
    const fullPath = this.createUrlWithParams(Endpoints.Privacy_Policy);
    return this.client.get<PaginatedResponseWrapperOvi<ContentResponse>>(fullPath);
  }

  createPrivacyPolicy(request: ContentRequest): Observable<BaseResponseWrapper<ContentResponse>> {
    const url = this.createUrlWithParams(Endpoints.Create_Privacy_Policy);
    return this.client.post<BaseResponseWrapper<ContentResponse>>(url, JSON.stringify(request));
  }

  getUserAgreement(): Observable<BaseResponseWrapper<ContentResponse>> {
    const fullPath = this.createUrlWithParams(Endpoints.User_Agreement);
    return this.client.get<PaginatedResponseWrapperOvi<ContentResponse>>(fullPath);
  }

  createUserAgreement(request: ContentRequest): Observable<BaseResponseWrapper<ContentResponse>> {
    const url = this.createUrlWithParams(Endpoints.Create_User_Agreement);
    return this.client.post<BaseResponseWrapper<ContentResponse>>(url, JSON.stringify(request));
  }

}
