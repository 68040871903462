import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'image-zoom',
  templateUrl: './image-zoom.component.html',
  styleUrls: ['./image-zoom.component.scss']
})
export class ImageZoomComponent implements OnInit {
  @Input() imageUrl:string ="";
  @ViewChild('image', { static: false }) image: ElementRef<HTMLImageElement>;
  // imageUrl = "/assets/images/testfis2.jpg";
  defaultImage = "/assets/images/404-image.jpg"
  scale = 1;
  translateX = 0;
  translateY = 0;
  isDragging = false;
  startX = 0;
  startY = 0;
  minScale = 1;  // Minimum ölçek değeri
  isLoading: boolean = false;
  constructor() { }

  ngOnInit(): void {
    this.isLoading = true;
    if (this.imageUrl.length > 0) {
      this.isLoading = false;
    }
    else {
      this.imageUrl = this.defaultImage
      this.isLoading = false;
    }
  }

  zoomIn() {
    this.scale += 0.1;
  }

  zoomOut() {
    if (this.scale > this.minScale) {
      this.scale -= 0.1;
    }
  }

  downloadImage() {
    const link = document.createElement('a');
    link.href = this.imageUrl;
    link.download = `Transaction_Image.jpg`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  openImageInNewTab() {
    const link = document.createElement('a');
    link.href = this.imageUrl;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  onWheel(event: WheelEvent) {
    let imageWrapper = document.getElementById('imageWrapper')
    if(this.scale > 1){
      imageWrapper.classList.add('zoom-out')
    }else{
      imageWrapper.classList.remove('zoom-out')
    }
    
    event.preventDefault();
    const delta = event.deltaY > 0 ? -0.1 : 0.1;
    if (this.scale + delta >= this.minScale) {
      this.scale += delta;
    }
    else {
      this.translateX = 0;
      this.translateY = 0;
    }
  }

  onMouseDown(event: MouseEvent) {
    if(this.scale <= 1){
      this.translateX = 0;
      this.translateY = 0;
      return;
    }
    if (event.button === 0) {  // Sol tıklama kontrolü
      this.isDragging = true;
      this.startX = event.clientX - this.translateX;
      this.startY = event.clientY - this.translateY;
    }
  }

  onMouseMove(event: MouseEvent) {
    if (this.isDragging) {
      this.translateX = event.clientX - this.startX;
      this.translateY = event.clientY - this.startY;
    }
  }

  onMouseUp() {
    this.isDragging = false;
  }
  onImageError(){
    this.imageUrl = this.defaultImage;
  }
}
