import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { NbToastrConfig } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class NotificationService {
   constructor(
      private translate: TranslateService,
      private toastr: NbToastrService) { }

   showSuccessToast(message: string = null, title: string = null, config?: Partial<NbToastrConfig>) {
      title = title ? title : this.translate.instant('COMMON.SUCCESS');
      message = message ? message : this.translate.instant('NOTIFICATIONS.PROCESS-SUCCEEDED');
      this.toastr.success(message, title, config);
   }

   showErrorToast(message: string = null, title: string = null, config?: Partial<NbToastrConfig>) {
      title = title ? title : this.translate.instant('COMMON.ERROR');
      message = message ? message : this.translate.instant('NOTIFICATIONS.PROCESS-FAILED');
      this.toastr.danger(message, title, config);
   }
   
   showWarningToast(message: string, title: string = null, config?: Partial<NbToastrConfig>) {
      title = title ? title : this.translate.instant('COMMON.WARNING');
      this.toastr.warning(message, title, config);
   }

   showInfoToast(message: string = null, title: string = null, config?: Partial<NbToastrConfig>) {
      title = title ? title : this.translate.instant('COMMON.INFO');
      message = message ? message : this.translate.instant('NOTIFICATIONS.PROCESS-FAILED');
      this.toastr.info(message, title, config);
   }
}
