import { NgModule } from '@angular/core';
import { AccountService } from './account.service';
import { AuthGuard } from './auth-guard.service';
import { BaseService, HttpClientService } from './base.service';
import { CommonService } from './common.service';
import { HelperService } from './helper.service';
import { NotificationService } from './notification.service';
import { SharedService } from './shared.service';
import { UserService } from './user.service';
import { TransactionService } from './transaction.service';
import { CompanyService } from './company.service';
import { AccountantService } from './accountant.service';
import { ReportService } from './report.service';
import { ContentService } from './content.service';
import { PaymentService } from './payment.service';
import { CreditCardService } from './credit-card.service';
import { CompanyGroupService } from './company-group.service';
import { UserGroupService } from './user-groups.service';
import { AgentService } from './agent.service';
import { GibDocumentService } from './gib-document-service';
import { EDefterService } from './edefter.service';
import { VatAcceptanceService } from './vat-acceptance.service';
import { I18nService } from './i18n.service';
import { GeographicalService } from './geographical.service';
import { TaxService } from './tax.service';
import { TaxOfficeService } from './tax-office.service';
import { AccountingPartyService } from './accounting-party.service';
import { CourierReconciliationService } from './courier-reconciliation.service';

@NgModule({
  providers: [
    BaseService,
    HttpClientService,
    SharedService,
    CommonService,
    NotificationService,
    HelperService,
    AuthGuard,
    AccountService,
    UserService,
    TransactionService,
    CompanyService,
    AccountantService,
    ReportService,
    ContentService,
    PaymentService,
    CreditCardService,
    CompanyGroupService,
    UserGroupService,
    AgentService,
    GibDocumentService,
    EDefterService,
    VatAcceptanceService,
    I18nService,
    GeographicalService,
    TaxService,
    TaxOfficeService,
    AccountingPartyService,
    CourierReconciliationService
  ],
})
export class ServicesModule { }
