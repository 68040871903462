import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Keys } from '../../@core/constants/keys';
import { UserResponse } from '../../@core/models/user';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  userData: UserResponse;
  constructor(private translate: TranslateService) {
    if (localStorage.getItem(Keys.USER_DATA)) {
      this.userData = <UserResponse>JSON.parse(localStorage.getItem(Keys.USER_DATA));
    }
  }
  public isUserValidForRoles(roleString: string): boolean {
    const roles = roleString.split(',');

    for (const role of roles) {
        for (const userRole of this.userData.roles) {
            if (userRole.name === role) {
                return true;
            }
        }
    }
    return false;
}
}
