export const Keys = {
    USER_ID: 'USER_ID',
    USER_TOKEN: 'USER_KEY',
    USER_DATA: 'USER_DATA',
    CURRENT_COMPANY: 'CURRENT_COMPANY',
    LANGUAGE: 'LANGUAGE',
    TRANSLATIONS_LANGUAGE_CODES: ['tr-tr', 'en-us'],
    SUPPORTED_LANGUAGES: 'SUPPORTED_LANGUAGES',
    COURIER_DETAIL:'COURIER_DETAIL',
    APP_VERSION:'APP_VERSION'
};
export const Values = {
    APP_VERSION_VALUE: 'Pmz8uKYrxIA6yza8Ey8'
}

export const OrderOptions = {
    Default: 'Default',
    Asc: 'Asc',
    Desc: 'Desc',
};
