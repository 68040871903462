import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NbAuthModule } from '@nebular/auth';
import {
      NbAlertModule,
      NbButtonModule,
      NbCardModule,
      NbCheckboxModule,
      NbDialogModule,
      NbIconModule,
      NbInputModule,
      NbLayoutModule,
      NbRadioModule,
      NbSelectModule,
      NbSpinnerModule,
      NbTabsetModule,
      NbThemeModule,
      NbDatepickerModule,
      NbCalendarRangeModule,
      NbBadgeModule,
      NbStepperModule,
} from '@nebular/theme';
import { TranslateModule } from '@ngx-translate/core';
import { HelperComponentsModule } from './@core/components/helper-components.module';
import { DirectivesModule } from './@core/directives/directives.module';
import { PipesModule } from './@core/pipes/pipes.module';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from 'primeng/calendar';
import { ContextMenuModule } from 'primeng/contextmenu';
import { FileUploadModule } from 'primeng/fileupload';
import { RadioButtonModule } from 'primeng/radiobutton';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BlankComponent } from './pages/blank/blank.component';
import {BreadcrumbModule} from 'primeng/breadcrumb';


@NgModule({
      declarations: [
            BlankComponent
      ],
      imports: [
            CommonModule,
            FormsModule,
            ReactiveFormsModule,
            RouterModule,
            NbCardModule,
            NbAlertModule,
            NbInputModule,
            NbButtonModule,
            NbCheckboxModule,
            NbAuthModule,
            NbThemeModule,
            NbSpinnerModule,
            NbLayoutModule,
            NbIconModule,
            NbRadioModule,
            NbSelectModule,
            NbTabsetModule,
            NbBadgeModule,
            NbDialogModule.forChild(),
            NbDatepickerModule,
            NbCalendarRangeModule,
            TableModule,
            PaginatorModule,
            AutoCompleteModule,
            DropdownModule,
            DirectivesModule,
            PipesModule,
            HelperComponentsModule,
            TranslateModule.forChild(),
            CalendarModule,
            MultiSelectModule,
            ContextMenuModule,
            FileUploadModule,
            NbStepperModule,
            NgbModule,
            BreadcrumbModule
      ],
      exports: [
            TranslateModule,
            CommonModule,
            TableModule,
            PaginatorModule,
            AutoCompleteModule,
            DropdownModule,
            FormsModule,
            RouterModule,
            NbCardModule,
            NbAlertModule,
            NbInputModule,
            NbButtonModule,
            NbCheckboxModule,
            NbAuthModule,
            NbThemeModule,
            NbSpinnerModule,
            NbLayoutModule,
            NbIconModule,
            NbRadioModule,
            NbSelectModule,
            NbDialogModule,
            NbTabsetModule,
            NbBadgeModule,
            NbDatepickerModule,
            NbCalendarRangeModule,
            DirectivesModule,
            PipesModule,
            HelperComponentsModule,
            CalendarModule,
            MultiSelectModule,
            ContextMenuModule,
            FileUploadModule,
            NbStepperModule,
            RadioButtonModule,
            BlankComponent,
            BreadcrumbModule
      ],
})
export class SharedModule {
}
