import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import 'rxjs/operators/catchError';
import 'rxjs/operators/map';
import { Observable } from 'rxjs/Rx';
import { environment } from '../../../environments/environment';
import { Keys } from '../constants/keys';
import { BaseResponseWrapper, PaginatedResponseWrapper } from '../models/api-response-wrappers';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Injectable()
export class HttpClientService {
    protected router: Router;

    constructor(
        public http: HttpClient,
        public translateService: TranslateService,
    ) {
    }

    createAuthorizationHeader(headers: HttpHeaders): HttpHeaders {
        const token = localStorage.getItem(Keys.USER_TOKEN);
        const language =
            localStorage.getItem(Keys.LANGUAGE)
                ? localStorage.getItem(Keys.LANGUAGE)
                : this.translateService.currentLang;
        if (token != null) {
            headers = headers.append('Authorization', 'Bearer ' + token);
        }
        headers = headers.append(
            'Accept-Language',
            language + ';q=0.7',
        );
        headers = headers.append('Content-Type', 'application/json');
        return headers;
    }

    public get<T>(url: string, isDocument?: boolean): Observable<any> {
        let headers = new HttpHeaders();
        headers = this.createAuthorizationHeader(headers);
        if (isDocument) {
            return this.http
                .get(url, { headers: headers, responseType: 'blob' })
                .pipe(catchError(this.handleError));
        }

        return this.http
            .get<T>(url, { headers: headers })
            .pipe(catchError(this.handleError));
    }

    public getRaw<T>(url: string): Observable<any> {
        let headers = new HttpHeaders();
        headers = this.createAuthorizationHeader(headers);
        return this.http.get(url, { headers: headers, responseType: 'blob', observe: 'response' }).pipe(catchError(this.handleError));
    }

    public post<T>(url: string, data: any = null, contentType?: string): Observable<any> {

        if (typeof (data) == "object")
            data = JSON.stringify(data);

        let headers = new HttpHeaders();
        headers = this.createAuthorizationHeader(headers);

        if (contentType != null) {
            headers = headers.append('Content-Type', contentType);
        }
        return this.http
            .post<T>(url, data, { headers: headers })
            .pipe(catchError(this.handleError));
    }

    public patch<T>(url: string, data: string = null): Observable<any> {
        let headers = new HttpHeaders();
        headers = this.createAuthorizationHeader(headers);
        return this.http
            .patch<T>(url, data, { headers: headers })
            .pipe(catchError(this.handleError));
    }

    public postMultiPart<T>(url: string, data: FormData = null): Observable<any> {
        let headers = new HttpHeaders();
        const token = localStorage.getItem(Keys.USER_TOKEN);
        const language = localStorage.getItem(Keys.LANGUAGE);
        if (token != null) {
            headers = headers.append('Authorization', 'Bearer ' + token);
        }
        headers = headers.append(
            'Accept-Language',
            language + ';q=0.7',
        );
        return this.http
            .post<T>(url, data, { headers: headers })
            .pipe(catchError(this.handleError));
    }
    /*
        Bu servis bir request ve response olarak file yüklediğimizde kullanıla bilir 
    */
    public customPostMultiPart<T>(url: string, data: FormData = null): Observable<any> {
        let headers = new HttpHeaders();
        // let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        const token = localStorage.getItem(Keys.USER_TOKEN);
        const language = localStorage.getItem(Keys.LANGUAGE);
        if (token != null) {
            headers = headers.append('Authorization', 'Bearer ' + token);
        }
        headers = headers.append(
            'Accept-Language',
            language + ';q=0.7',
        );
        // headers.append('Content-Type', 'application/json');
        const options = {
            headers: headers,
            responseType: 'blob' as 'json' // Expect a binary response
        };
        return this.http
            .post<T>(url, data, options)
            .pipe(catchError(this.handleError));
    }
    public putMultiPart<T>(url: string, data: FormData = null): Observable<any> {
        let headers = new HttpHeaders();
        const token = localStorage.getItem(Keys.USER_TOKEN);
        const language = localStorage.getItem(Keys.LANGUAGE);
        if (token != null) {
            headers = headers.append('Authorization', 'Bearer ' + token);
        }
        headers = headers.append(
            'Accept-Language',
            language + ';q=0.7',
        );
        return this.http
            .put<T>(url, data, { headers: headers })
            .pipe(catchError(this.handleError));
    }
    public put<T>(url: string, data: string = null): Observable<any> {
        let headers = new HttpHeaders();
        headers = this.createAuthorizationHeader(headers);
        return this.http
            .put<T>(url, data, { headers: headers })
            .pipe(catchError(this.handleError));
    }

    public delete<T>(url: string, data: string = null): Observable<any> {
        let headers = new HttpHeaders();
        headers = this.createAuthorizationHeader(headers);

        const httpOptions = { headers: headers, body: data };

        return this.http
            .delete<T>(url, httpOptions)
            .pipe(catchError(this.handleError));
    }

    public handleError(response: any) {
        const errorBody = response.error;
        if (response.status === 401) {
            localStorage.clear();
            sessionStorage.clear();
            window.location.href = 'account/login';
        } else if (response.status === 403) {
            // this.router.navigate(['/dashboard']);
            // window.location.href = 'dashboard';
        }
        return observableThrowError(errorBody);
    }
}

@Injectable()
export class DataService {
    protected BASE_API_URL = environment.api_base_url;
    protected client: HttpClientService;
    constructor(client: HttpClientService) {
        this.client = client;
    }
    public createUrlWithParams(urlPath: string, json: Object = null): string {
        for (const key in json) {
            if (json.hasOwnProperty(key)) {
                urlPath = urlPath.replace(
                    `{${key}}`,
                    json[key] === null ? '' : json[key],
                );
            }
        }

        return this.BASE_API_URL + urlPath;
    }
}

@Injectable()
export class BaseService<TRequest, TResponse> extends DataService {
    constructor(
        client: HttpClientService,
        @Inject('BASE_ENDPOINT') public endPoint: string,
    ) {
        super(client);
    }

    public getItems(
        page: number = 0,
        size: number = 20,
        q: string = null,
        sort_by: string = null,
        order_by: string = null,
    ): Observable<PaginatedResponseWrapper<TResponse>> {
        let fullPath = `${this.BASE_API_URL + this.endPoint}?page=${page}&size=${size}`;

        if (q) {
            fullPath += `&q=${q}`;
        }
        if (sort_by) {
            fullPath += `&sort_by=${sort_by}`;
        }
        if (order_by) {
            fullPath += `&order_by=${order_by}`;
        }

        return this.client.get<PaginatedResponseWrapper<TResponse>>(fullPath);
    }

    public getItemsByQuery(
        query: string,
    ): Observable<PaginatedResponseWrapper<TResponse>> {
        const fullPath = `${this.BASE_API_URL +
            this
                .endPoint}?${query}`;

        return this.client.get<PaginatedResponseWrapper<TResponse>>(fullPath);
    }


    public getItem(id: string): Observable<BaseResponseWrapper<TResponse>>;
    public getItem(id: string): Observable<BaseResponseWrapper<any>> {
        const fullPath = `${this.BASE_API_URL + this.endPoint}/${id}`;
        return this.client.get<BaseResponseWrapper<any>>(fullPath);
    }

    public createItem(item: TRequest): Observable<BaseResponseWrapper<TResponse>>;
    public createItem(item: any): Observable<BaseResponseWrapper<any>> {
        const fullPath = `${this.BASE_API_URL + this.endPoint}`;
        return this.client.post<BaseResponseWrapper<any>>(
            fullPath,
            JSON.stringify(item),
        );
    }

    public updateItem(
        id: string,
        item: TRequest,
    ): Observable<BaseResponseWrapper<TResponse>>;
    public updateItem(
        id: string,
        item: any,
    ): Observable<BaseResponseWrapper<any>> {
        const fullPath = `${this.BASE_API_URL + this.endPoint}/${id}`;
        return this.client.put<BaseResponseWrapper<any>>(
            fullPath,
            JSON.stringify(item),
        );
    }

    public deleteItem(id: string): Observable<BaseResponseWrapper<TResponse>>;
    public deleteItem(id: string): Observable<BaseResponseWrapper<any>> {
        const fullPath = `${this.BASE_API_URL + this.endPoint}/${id}`;
        return this.client.delete<BaseResponseWrapper<any>>(fullPath);
    }
}
