import { DOCUMENT, registerLocaleData } from '@angular/common';
import localeTrExtra from '@angular/common/locales/extra/tr';
import localeTr from '@angular/common/locales/tr';
import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NbDialogService } from '@nebular/theme';
import { PaymentService } from './@core/services/payment.service';
import { SharedService } from './@core/services/shared.service';
import { PaymentResponse } from './@core/models/payment';
import { NotificationService } from './@core/services/notification.service';
import { ActivatedRoute, Router, RouterLinkActive } from '@angular/router';
import { UserService } from './@core/services/user.service';

@Component({
  selector: 'ngx-app',
  template: `
  <div [nbSpinner]="isLoading" nbSpinnerStatus="primary" nbSpinnerSize="large">
      <ng-template #dialog let-data let-ref="dialogRef" >
      <nb-card>
          <nb-card-header>
              <nb-icon icon="info" status="info"></nb-icon> {{'COMMON.WARNING'|translate}}
          </nb-card-header>
          <nb-card-body>
              {{ data.lastPaymentDate | date:'dd.MM.yyyy' }} tarihine kadar ödemeniz gereken {{ data.amount }}TL tutarında borcunuz bulunmaktadır ({{data.cause}}). BiMasraf'ı kullanmaya devam edebilmek için lütfen ödeme yapınız.
          </nb-card-body>
          <nb-card-footer class="example-items-rows">
              <button nbButton status="success" size="medium"
                  (click)="pay(data);ref.close()">Öde</button>
              <button nbButton outline status="danger" size="medium"
                  (click)="ref.close()">{{'COMMON.CLOSE'|translate}}</button>
          </nb-card-footer>
      </nb-card>
    </ng-template>

    <router-outlet></router-outlet>
  </div>

  `,
  providers:[RouterLinkActive]
})
export class AppComponent implements OnInit {
  @ViewChild('dialog', { static: true }) dialog: TemplateRef<any>;

  isLoading = false;
  message = '';
  constructor(
    @Inject(DOCUMENT) private document: Document,
    translate: TranslateService,
    private sharedService: SharedService,
    protected dialogService: NbDialogService,
    private paymentService: PaymentService,
    private notificationService: NotificationService,
    private userService: UserService,
    private router: Router,
    private ff : RouterLinkActive
  ) {
    //this.requestVat()
    translate.setDefaultLang('tr');
    if (!sharedService.language) {
      // sharedService.setLanguage(translate.getBrowserLang());
      sharedService.setLanguage('tr');
    }
    translate.addLangs(['tr', 'en']);
    translate.use(sharedService.language);

    registerLocaleData(localeTr, 'tr', localeTrExtra);
    if (sharedService.userData != null && sharedService.userData.roles.find(x => x.name === 'member')) {
      paymentService.getUpcomingPayment().subscribe(x => {
        if (x.data != null && x.data['amount'] > 0 ) {
          this.router.navigate(['']).then(() =>
            dialogService.open(this.dialog, { context: x.data })
          );
        }
      });
    }
    this.checkToken();
   
  }

  ngOnInit() {
    this.document.documentElement.lang = 'tr'; 
     this.sharedService.userControl();
     if(this.sharedService.userData.roles[0].name === 'courierAgentBasic'){
      this.router.navigateByUrl('admin/courier/courier-query')
     }
  }

  pay(payment: PaymentResponse) {
    this.isLoading = true;
    this.paymentService.pay(payment.id, '').subscribe(x => {
      this.isLoading = false;
      this.notificationService.showSuccessToast('Ödemeniz alınmıştır. Teşekkür ederiz.');
    }, err => {
      this.isLoading = false;
      this.router.navigate(['/account/payment-methods']).then(() =>
        this.notificationService.showErrorToast('Kredi kartı bilgilerinizi güncellemeniz gerekmektedir.')
      );
    });
  }

  checkToken() {

    const standalone = this.ff['router'].config.find(x => {return x.path == 'standalone'});

    if (standalone == undefined) {
      this.userService.userCheckToken().subscribe(response => {
        if (response.data != true) {
          this.sharedService.logOut();
        }
      });

    }
  }
}
