import { Injectable } from '@angular/core';
import { BaseService, HttpClientService } from './base.service';
import { TaxExemption } from '../models/tax/tax-exemption';
import { BaseResponseWrapper } from '../models/api-response-wrappers';

const Endpoints = {
    Controller: 'tax',
    ListTaxExemptions: 'tax/list-tax-exemptions'
};

@Injectable()
export class TaxService extends BaseService<any, any> {

    constructor(httpClient: HttpClientService) {
        super(httpClient, Endpoints.Controller);
    }

    async listTaxExemptions(): Promise<TaxExemption[]> {
        const fullPath = this.createUrlWithParams(Endpoints.ListTaxExemptions, { });
        const response : BaseResponseWrapper<TaxExemption[]> = await this.client.get<BaseResponseWrapper<TaxExemption[]>>(fullPath).toPromise();
        if (response !== null && response.data !== null)
            return response.data;
        else
            throw response.message;
    }

}
