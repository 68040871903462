import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { AccountantRequest, AccountantResponse } from '../models/accountant';
import { OrderOptions, Keys } from '../constants/keys';
import { BaseService, HttpClientService } from './base.service';
import { PaginatedResponseWrapperOvi, BaseResponseWrapper } from '../models/api-response-wrappers';

const Endpoints = {
    Controller: 'users/me/accountants',
    Accountants: 'users/{id}/accountants?q={q}&page={page}&size={size}&sort_by={sort_by}&order_by={order_by}',
    Accountant: 'users/{id}/accountants/{accountantId}',
};

@Injectable()
export class AccountantService extends BaseService<AccountantRequest, AccountantResponse> {

    constructor(httpClient: HttpClientService) {
        super(httpClient, Endpoints.Controller);
    }

    getAccountants(
        page: number = 0,
        size: number = 30,
        q: string = '',
        sort_by: any = '',
        order_by: string = OrderOptions.Default,
        userId: string = localStorage.getItem(Keys.USER_ID),
    ): Observable<PaginatedResponseWrapperOvi<AccountantResponse>> {
        const json = {
            page: page,
            size: size,
            q: q,
            sort_by: sort_by,
            order_by: order_by,
            id: userId,
        };
        const fullPath = this.createUrlWithParams(Endpoints.Accountants, json);
        return this.client.get<PaginatedResponseWrapperOvi<AccountantResponse>>(fullPath);
    }

    getAccountant(accountantId: string, userId: string = localStorage.getItem(Keys.USER_ID)): Observable<BaseResponseWrapper<AccountantResponse>> {
        const fullPath = this.createUrlWithParams(Endpoints.Accountant, { accountantId: accountantId, id: userId });
        return this.client.get<BaseResponseWrapper<AccountantResponse>>(fullPath);
    }

    createAccountant(request: AccountantRequest): Observable<BaseResponseWrapper<AccountantRequest>> {
        const url = this.createUrlWithParams(Endpoints.Controller);
        return this.client.post<BaseResponseWrapper<AccountantRequest>>(url, JSON.stringify(request));
    }

    updateAccountant(accountantId: string, request: AccountantRequest, userId: string = 'me'): Observable<BaseResponseWrapper<AccountantResponse>> {
        const url = this.createUrlWithParams(Endpoints.Accountant, { id: userId, accountantId: accountantId });
        return this.client.put<BaseResponseWrapper<AccountantResponse>>(url, JSON.stringify(request));
    }


}
