import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { CompanyResponse, CompanyRequest } from '../models/company';
import { OrderOptions, Keys } from '../constants/keys';
import { BaseService, HttpClientService } from './base.service';
import { PaginatedResponseWrapperOvi, BaseResponseWrapper } from '../models/api-response-wrappers';
import { isNullOrUndefined } from 'util';
import { EInvoiceInboxesResponse } from '../models/e-invoice-inboxes-response';

const Endpoints = {
    Controller: 'users/{userId}/companies',
    Companies: 'users/{id}/companies?companyGroupId={companyGroupId}&type={type}&page={page}&size={size}&q={q}&sort_by={sort_by}&order_by={order_by}',
    Company: 'users/{userId}/companies/{id}',
    EInvoiceInboxes: 'companies/e-invoice-inboxes/{taxNo}'
};

@Injectable()
export class CompanyService extends BaseService<any, CompanyResponse> {

    constructor(httpClient: HttpClientService) {
        super(httpClient, Endpoints.Controller);
    }

    getCompanies(
        type: string,
        page: number = 0,
        size: number = 30,
        q: string = '',
        sort_by: any = '',
        order_by: string = OrderOptions.Asc,
        companyGroupId = '',
        userId: string = localStorage.getItem(Keys.USER_ID),
    ): Observable<PaginatedResponseWrapperOvi<CompanyResponse>> {

        const json = {
            id: userId,
            type: type,
            page: page,
            size: size,
            q: q,
            sort_by: sort_by,
            order_by: order_by,
            companyGroupId: companyGroupId
        };

        const fullPath = this.createUrlWithParams(Endpoints.Companies, json);
        return this.client.get<PaginatedResponseWrapperOvi<CompanyResponse>>(fullPath);
    }

    createCompany(request: CompanyRequest, userId: string = localStorage.getItem(Keys.USER_ID)): Observable<BaseResponseWrapper<CompanyResponse>> {
        const url = this.createUrlWithParams(Endpoints.Controller, { userId: userId });

        let json = {
            name: request.name,
            email: request.email,
            phoneNumber: request.phoneNumber,
            type: request.type,
            iban: request.iban,
            identityNo: request.identityNo,
            taxNo: request.taxNo,
            taxOffice: request.taxOffice,
            contactFirstName: request.contactFirstName,
            contactLastName: request.contactLastName,
            companyGroupId: request.companyGroupId,
            invoiceAlias: request.invoiceAlias,
            isFromELedgerDeclaration: request.isFromELedgerDeclaration
        };

        const companyAddress = {
            address: request.address,
        };

        if (!isNullOrUndefined(request.address)) {
            json = { ...json, ...companyAddress };
        }

        return this.client.post<BaseResponseWrapper<CompanyResponse>>(url, JSON.stringify(json));
    }

    async eInvoiceInboxesAsync(taxNo: string): Promise<EInvoiceInboxesResponse> {
        const fullPath = (this.createUrlWithParams(Endpoints.EInvoiceInboxes, { taxNo: taxNo }));
        const response: BaseResponseWrapper<EInvoiceInboxesResponse> = await this.client.get<BaseResponseWrapper<EInvoiceInboxesResponse>>(fullPath).toPromise();
        return response.data;
    }

    getCompany(companyId: string, userId: string = localStorage.getItem(Keys.USER_ID)): Observable<BaseResponseWrapper<CompanyResponse>> {
        const fullPath = (this.createUrlWithParams(Endpoints.Company, { userId: userId, id: companyId }));
        return this.client.get<BaseResponseWrapper<CompanyResponse>>(fullPath);
    }

    updateCompany(companyId: string, request: CompanyRequest, userId: string = localStorage.getItem(Keys.USER_ID)): Observable<BaseResponseWrapper<CompanyResponse>> {
        const url = this.createUrlWithParams(Endpoints.Company, { userId: userId, id: companyId });

        let json = {
            name: request.name,
            email: request.email,
            phoneNumber: request.phoneNumber,
            type: request.type,
            iban: request.iban,
            identityNo: request.identityNo,
            taxNo: request.taxNo,
            taxOffice: request.taxOffice,
            contactFirstName: request.contactFirstName,
            contactLastName: request.contactLastName,
            companyGroupId: request.companyGroupId
        };

        const companyAddress = {
            address: request.address,
        };

        if (!isNullOrUndefined(request.address)) {
            json = { ...json, ...companyAddress };
        }

        return this.client.put<BaseResponseWrapper<CompanyResponse>>(url, JSON.stringify(json));
    }

    deleteCompany(companyId: string, userId: string = localStorage.getItem(Keys.USER_ID)): Observable<BaseResponseWrapper<any>> {
        const url = this.createUrlWithParams(Endpoints.Company, { id: companyId, userId: userId });
        return this.client.delete<BaseResponseWrapper<any>>(url);
    }
}
