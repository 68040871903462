import { Component, OnInit, Input } from '@angular/core';
import { MenuItem } from 'primeng/api';
@Component({
  selector: 'blank',
  templateUrl: './blank.component.html',
  styleUrls: ['./blank.component.scss']
})
export class BlankComponent implements OnInit {

  @Input() isLoading?: boolean = false;
  @Input() isLoadingMessage?: string;
  @Input() pageTitle?: string = 'Default Page Title';
  @Input() icon?: string = 'pi-th-large';
  // @Input() menuItems?: MenuItem[] = [];
  pageTitleIcon: string;

  items: MenuItem[];

  home: MenuItem;

  constructor() {
    setTimeout(()=>{
      this.pageTitleIcon = 'pi ' + this.icon;
    },500)
  }

  ngOnInit() {
    // this.items = this.menuItems;
    // this.home = { icon: 'pi pi-home', url: '/' };
   

  }

}
