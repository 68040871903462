import { Component } from '@angular/core';
import { BaseComponent } from '../../../shared/base.component';
import { LayoutService } from '../../services/layout.service';
import { SharedService } from '../../../@core/services/shared.service';
import ValidationUtilities from '../../../../classes/validation-utilities';
import { Keys } from '../../../@core/constants/keys';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout windowMode>
      <nb-layout-header fixed>
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive start [ngClass]="{'d-none': roles}" *ngIf="layoutService.isUserValidForRoles('admin') || layoutService.isUserValidForRoles('agent') || layoutService.userData.isRegisterCompleted">
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>

      <nb-layout-column>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <nb-layout-footer>
        <ngx-footer></ngx-footer>
      </nb-layout-footer>
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent {
  roles: boolean = false;
  constructor(public layoutService: LayoutService,
    private sharedService: SharedService
  ) {
    this.roles = this.sharedService.userData.roles[0].name === 'courierAgentBasic';
  }
}
