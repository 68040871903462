import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import { Keys, OrderOptions } from '../@core/constants/keys';
import { UserResponse, Relation } from '../@core/models/user';
import { Subscription } from 'rxjs';
import { I18nService } from '../@core/services/i18n.service';

export class BaseComponent {
    userData: UserResponse;
    environment = environment;
    loading = false;
    loaderMessage = this.getTranslate('COMMON.LOADING');
    searchInput: string;
    pageSize: any = 30;
    sort_by: string = '';
    order_by = OrderOptions.Desc;
    totalItemCount: any = 0;
    autoTableLayout: boolean = true;
    currentCompany: Relation = <Relation>{};
    subscription: Subscription;
    now: Date = new Date();

    constructor(
        private translate: TranslateService,
        private _i18nService?: I18nService) {
        if (localStorage.getItem(Keys.USER_DATA)) {
            this.userData = <UserResponse>JSON.parse(localStorage.getItem(Keys.USER_DATA));
        }
    }

    getUser(): UserResponse {
        return <UserResponse>JSON.parse(localStorage.getItem(Keys.USER_DATA));
    }

    setUser(data: UserResponse) {
        localStorage.setItem(Keys.USER_DATA, JSON.stringify(data));
    }

    getParameterByName(name, url) {
        if (!url) {
            url = url;
        }

        name = name.replace(/[\[\]]/g, '\\$&');
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';

        return decodeURIComponent(results[2].replace(/\%20/g, '+'));
    }

    public isUserValidForRoles(roleString: string): boolean {
        const roles = roleString.split(',');

        for (const role of roles) {
            for (const userRole of this.userData.roles) {
                if (userRole.name === role) {
                    return true;
                }
            }
        }
        return false;
    }

    public clearPhoneMask(phoneNumber: string) {
        const replacedPhone = phoneNumber
            .replace('(', ' ')
            .replace(')', ' ')
            .trim()
            .split(' ');
        let clearPhone = '';
        replacedPhone.forEach(element => {
            if (element !== ' ') {
                clearPhone += element;
            }
        });
        clearPhone.trim();
        return clearPhone;
    }

    getTranslate(key: string, params?: string[]): string {
        let translateResult: string;
        this.translate.get(key, params).subscribe((t: string) => {
            translateResult = t;
        });

        return translateResult;
    }

    getPosition(el, height) {
        let xPos = 0;
        let yPos = 0;

        while (el) {
            if (el.tagName === ' BODY ') {
                // deal with browser quirks with body/window/document and page scroll
                const xScroll = el.scrollLeft || document.documentElement.scrollLeft;
                const yScroll = el.scrollTop || document.documentElement.scrollTop;

                xPos += (el.offsetLeft - xScroll + el.clientLeft);
                yPos += (el.offsetTop - yScroll + el.clientTop);
            } else {
                // for all other non-BODY elements
                xPos += (el.offsetLeft - el.scrollLeft + el.clientLeft);
                yPos += (el.offsetTop - el.scrollTop + el.clientTop);
            }

            el = el.offsetParent;
        }
        return {
            x: xPos,
            y: (yPos - height) + 'px',
        };
    }

    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return (
            s4() +
            s4() +
            '-' +
            s4() +
            '-' +
            s4() +
            '-' +
            s4() +
            '-' +
            s4() +
            s4() +
            s4()
        );
    }
}
