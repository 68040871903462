import { IResponse } from './api-response-wrappers';
import { RoleResponse } from './role';
import { AddressResponse, AddressRequest } from './company';
import { SubUserGroupResponse, UserGroupResponse } from './user-group';
import { AdvisorTransactionRequest, TransactionRequest } from './transaction';
import { CompanyTypeEnum } from '../../../enums/company-type-enum';
import { PaymentResponse } from './payment';
import { PrivateIntegratorEnum } from '../../../enums/private-integrator-enum';
import { AccountModuleStatus, ModuleType } from './ETransformationEnum/eTransformEnumType';


export enum Gender {
    Undefined = 'Undefined',
    Male = 'Male',
    Female = 'Female',
}

export enum ObligationSpan {
    Month,
    ThreeMonths
}

export interface UserSimpleResponse extends IResponse {
    companyName: string;
    firstName: string;
    lastName: string;
    identityNo?: string;
    pricing?: number;
    phoneNumber?: string;
    email?: string;
    totalDebt: number;
    totalPaid: number;
    lastPaymentDate: Date;

    tag: string;
    code: string;
    agentName: string;
    notes: string;
    hasSignature: boolean;
    leaveReason: string;
    leaveType: string;
    leaveDate: Date;
    establishmentDate: Date;
    companyType: CompanyTypeEnum;
    subscriptionPeriod: SubscriptionPeriod;
}

export interface Permission {
    writeCompanies: boolean;
    writeTransactions: boolean;
    writeProducts: boolean;
    writeUsers: boolean;
    readCompanies: boolean;
    readTransactions: boolean;
    readProducts: boolean;
    readUsers: boolean;
}

export interface Relation extends Permission {
    mainUserId: string;
    mainUser: UserSimpleResponse;
    invitedUserId: string;
    invitedUser: UserSimpleResponse;
    description: string;
    isAccountant: boolean;
}

export interface UserResponse extends UserSimpleResponse {
    externalId: string;
    gender: any;
    documentType: string;
    birthDate: Date;
    startTimeOfSubscription: Date;
    imageUrl: string;
    roles: RoleResponse[];
    receivedRelations: Relation[];
    givenRelations: Relation[];
    address: AddressResponse;
    isRegisterCompleted: boolean;
    taxNo: string;
    taxOffice: string;
    isSmsAdvertisingPermitted: boolean;
    isEmailAdvertisingPermitted: boolean;
    eInvoiceUsername: string;
    bimasrafFee: boolean;
    eInvoicePassword: string;
    isEInvoiceCredentialsCorrect: boolean;
    shutDownDate: Date;
    iban: string;
    vdUsername: string;
    vdPassword: string;
    vdParola: string;

    sgkUsername: string;
    sgkPassword: string;
    sgkOfficePassword: string;

    egPassword: string;

    eDefterAdvisorSecret: string,
    eDefterAdvisorKey: string,
    eDefterTaxOffice: string,

    kdv1: ObligationSpan;
    kdv2: boolean;
    ba: boolean;
    bs: boolean;
    gecici: boolean;
    damga: boolean;
    kurumlar: boolean;
    yillikGelir: boolean;
    muhSgk: ObligationSpan;

    group: UserGroupResponse;
    groupId: string;
    subGroup: SubUserGroupResponse;
    subGroupId: string;

    agentId: string;
    agentId2: string;
    agent: UserResponse;

    advisorId: string;
    advisor: UserResponse;

    isAgent?: boolean;
    isAdvisor?: boolean;
    isCustomer?: boolean;

    agentName: string;
    groupName: string;

    gibDocuments: GibDocumentResponse[];
    eSignatureStartDate?: Date;
    eSignatureSubscriptionPeriod: number;
    payments: PaymentResponse;
    eInvoiceToken: string;
    privateIntegrator?: PrivateIntegratorEnum;

    subscriptionPeriod: SubscriptionPeriod;
    gbAliases: any;
    userCourierCompany: UserCorierCompany[];
    userETransformations:UserETransformations[];
}
export class UserETransformations {
    moduleType:ModuleType;
    accountModuleStatus:AccountModuleStatus;
}
export class UserCorierCompany{
    companyAgentId:string;
    companyId:number;
    companyName:string;
    companyShortName:string;
    externalId:string;
    externalIdType:string;
    id:number;
    isExternalIdCanBeDeleted:boolean;
    isExternalIdCanBeUpdated:boolean;
    userId:string;

}

export interface AdvisorInvoicesUserResponse extends UserResponse {
    transactionRequest: AdvisorTransactionRequest;
}

export interface GibDocumentResponse {
    beyannameType: string;
    beyannameUrl: string;
    tahakkukUrl: string;
    taxPeriodStartDate: Date;
    taxPeriodEndDate: Date;
}


export class UserRequest {
    companyName = null;
    firstName = null;
    lastName = null;
    email = null;
    phoneNumber = null;
    birthDate = null;
    gender = Gender.Undefined;
    documentType = null;
    address = new AddressRequest();
    taxNo = null;
    taxNumberInput?: string = null;
    taxOffice = null;
    isSmsAdvertisingPermitted = false;
    isEmailAdvertisingPermitted = true;
    eInvoiceUsername = null;
    eInvoicePassword = null;
    bimasrafFee = false;
    identityNo?: string;
    pricing?: number;
    iban: string = null;
    subscriptionPeriod: SubscriptionPeriod;
}

export class UserUpdateByAdminRequest {
    externalId: string = null;
    companyName: string = null;
    firstName: string = null;
    lastName: string = null;
    email: string = null;
    phoneNumber: string = null;
    birthDate: Date = null;
    startTimeOfSubscription: Date = null;
    gender = Gender.Undefined;
    documentType = null;
    address = new AddressRequest();
    bimasrafFee = false;
    identityNo? = '';
    pricing?: number;
    taxNo: string = null;
    taxOffice: string = null;

    tag: string = null;
    code: string = null;
    agentName: string = null;
    notes: string = null;
    hasSignature: string = null;
    leaveReason: string = null;
    leaveType: string = null;
    leaveDate: Date = null;
    establishmentDate: Date = null;

    eInvoiceUsername: string = null;
    eInvoicePassword: string = null;

    vdUsername: string = null;
    vdPassword: string = null;
    vdParola: string = null;

    sgkUsername: string = null;
    sgkPassword: string = null;
    sgkOfficePassword: string = null;

    egPassword: string = null;
    egUsername: string = null;

    eDefterAdvisorSecret = '';
    eDefterAdvisorKey = '';
    eDefterTaxOffice = '';


    kdv1: ObligationSpan = null;
    kdv2: boolean = null;
    ba: boolean = null;
    bs: boolean = null;
    gecici: boolean = null;
    damga: boolean = null;
    kurumlar: boolean = null;
    yillikGelir: boolean = null;
    muhSgk: ObligationSpan = null;

    groupId: string = null;
    subGroupId: string = null;


    isAgent: boolean = null;
    isAdvisor: boolean = null;
    isCustomer: boolean = null;

    agentId: string = null;
    agentId2: string = null;
    advisorId: string = null;

    internalNo: string = null;

    naceKodu: string = null;
    iban: string = null;
    eSignatureStartDate: Date = null;
    eSignatureSubscriptionPeriod: number;

    eInvoiceToken: string = null;
    privateIntegrator?: PrivateIntegratorEnum = null;

    subscriptionPeriod: SubscriptionPeriod = null;
    gbAliases:any;
    invoiceAlias:string = null;
    userETransformations:UserETransformations[];
}

export class UserCreateByAdminRequest extends UserUpdateByAdminRequest {
    password: string = null;
    taxNumberInput: string = null;
}

export class UserSearchParams {
    companyName?: string;
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    email?: string;
    isActive = true;
    isEInvoiceCredentialsCorrect?: boolean;

    isAgent?: boolean;
    isPriorAdmin: boolean;
    isAdvisor?: boolean;
    isCustomer?: boolean;

    agentId: string;
    agentId2: string;
    advisorId: string;

    onlyLeaved?: boolean;

    taxNo?: string;
    taxOffice?: string;

    shutDownDateStart?: Date;
    shutDownDateEnd?: Date;

    totalDebtBiggerThan?: number;
    totalDebtSmallerThan?: number;

    totalPaidBiggerThan?: number;
    totalPaidSmallerThan?: number;

    lastPaymentDateStart?: Date;
    lastPaymentDateEnd?: Date;

    groupId?: string;
    subGroupId?: string;
    code?: string;
    agentName?: string;
    notes?: string;
    hasSignature?: boolean;

    leaveReason?: string;
    leaveType?: string;

    createDateStart?: Date;
    createDateEnd?: Date;

    leaveDateStart?: Date;
    leaveDateEnd?: Date;

    establishmentDateStart?: Date;
    establishmentDateEnd?: Date;

    selected: boolean = false;
    getAll: boolean = false;
    userTotalDebt_otherBalance?: boolean = null;
    userTotalDebt_platformBalance?: boolean = null;
    userTotalDebt_accountingBalance?: boolean = null;
    userTotalDebt_totalBalance?: boolean = null;
    subscriptionPeriod?: SubscriptionPeriod;
    subscriptionPeriodStart?: Date;
    subscriptionPeriodEnd?: Date;
    status?:number;
    courierCompanyId?:number; 
}

export class KeyValueResponse {
    key: string;
    value?: string;
}
export class userPaymentResponse implements IResponse {
    id: string;
    isActive: boolean;
    createDate: Date;
    taxNo: string;
    firstName: string;
    lastName: string;
    agentId: string;
    agent: string;
    advisorId: string;
    advisor: string;
    userTotalDebt?: UserTotalDebt;
    subscriptionPeriod: SubscriptionPeriod;
    subscriptionPeriodStart?: Date;
    subscriptionPeriodEnd?: Date;
    status:number;
}
export class UserTotalDebt {
    accountingBalance?: number;
    platformBalance?: number;
    otherBalance?: number;
    totalBalance?: number;
}
export enum SubscriptionPeriod {
    Yearly = 1,
    Monthly = 2
}
export class SenderTags {
    id: number;
    identifier: string;
    identifierType: string;
    type: string;
    value: string;
    isDefault: boolean;
    timeOfAliasCreate: Date;
    timeOfCreate: Date;
}
export class CourierCompanyiLstResponse {
    id: number;
    name: string;
    validCompanyTypes:string;
    isExternalIdCanBeDeleted:boolean;
    isExternalIdCanBeUpdated:boolean;
    courierCompany:CourierCompany
}
export class CourierCompanyRequest {
    userId: string = null;
    companyId: number = null;
    externalId: string = null;
}
export class CourierCompanyResponse implements IResponse {
    id: string;
    isActive: boolean;
    createDate: Date;
    userId: string;
    companyId: number;
    companyName: string;
    externalId: string;
    courierCompany:CourierCompany
}
export class CourierCompany {
    agentId:string;
    email:string;
    id:number;
    name:string;
}
export class AgentReportResponse {
    agentId: string;
    firstName: string;
    lastName: string;
    agentEmail: string;
    isDeleted: boolean;
    isActive: boolean;
    currentUserCount: number;
    newCount: number;
    monthlyLeftCount: number;
    yearlyLeftCount: number;
    monthlyAccountingLeftCount: number;
    yearlyAccountingLeftCount: number;
}


export class CourierShortName{
    id:number;
    shortName:string;
}
