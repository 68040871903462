// declare global {
    interface String {
        removeDiacritics(): string;
        normaliseForComparison(): string;
        toUpperCaseFirst(): string;
        toLocaleUpperCaseFirst(): string;
        safeTrim():string;
    }
// }

String.prototype.removeDiacritics = function (): string {
    return this.normalize('NFD').replace(/\p{Diacritic}/gu, '');
}

String.prototype.normaliseForComparison = function (): string {
    return this.removeDiacritics().toLowerCase();
}

String.prototype.toUpperCaseFirst = function (): string {
    return this.charAt(0).toUpperCase() + this.slice(1);
}

String.prototype.toLocaleUpperCaseFirst = function (): string {
    return this.charAt(0).toLocaleUpperCase() + this.slice(1);
}

String.prototype.safeTrim = function (): string {
    return (this || "").trim()
}

// export { }