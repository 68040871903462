import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { TransactionRequest, TransactionResponse, OCRResponse, TransactionSearchParams, TransactionAcceptanceStatusRequest, TransactionAcceptanceStatus, TransactionStatusUpdateRequest, TransactionType, InvoiceAnswerRequest } from '../models/transaction';
import { OrderOptions, Keys } from '../constants/keys';
import { BaseService, HttpClientService } from './base.service';
import { PaginatedResponseWrapperOvi, BaseResponseWrapper } from '../models/api-response-wrappers';
import { I18nEnum } from '../models/i18n-enum';

const Endpoints = {
    Controller: 'i18n',
    EnumValues: 'i18n/enum-values/{enumName}'
};

@Injectable()
export class I18nService extends BaseService<any, any> {

    constructor(httpClient: HttpClientService) {
        super(httpClient, Endpoints.Controller);
    }

    async getEnumValues(enumName: string): Promise<I18nEnum[]> {
        const fullPath = this.createUrlWithParams(Endpoints.EnumValues, { enumName: enumName });
        const response : BaseResponseWrapper<I18nEnum[]> = await this.client.get<BaseResponseWrapper<I18nEnum[]>>(fullPath).toPromise();
        if (response !== null && response.data !== null)
            return response.data;
        else
            throw response.message;
    }
}
