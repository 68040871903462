import { Component, Output, EventEmitter, Input, ElementRef, ViewChild } from '@angular/core';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { NotificationService } from '../../services/notification.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
})
export class ImageUploadComponent {

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  @Output() onUpload = new EventEmitter();
  @Input() previewUrl: any = null;
  @Input() buttonType: any = null;
  @Input() buttonText: string = '';
  fileData: File = null;
  fileUploadProgress: string = null;


  localUrl: any;
  localCompressedURl: any;
  sizeOfOriginalImage: number;
  sizeOFCompressedImage: number;

  uploading: boolean;

  constructor(
    private ng2ImgMax: Ng2ImgMaxService,
    protected notificationService: NotificationService,
    protected translateService: TranslateService,
  ) { }

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    fileInput.target.value = '';
    this.compressFile();
  }

  compressFile() {
    this.uploading = true;
    this.ng2ImgMax.resizeImage(this.fileData, 1200, 866).subscribe(
      result => {
        this.preview();
        let uploadedImage: File = result;
        this.onUpload.emit(uploadedImage);
        this.uploading = false;
      },
      error => {
        this.notificationService.showErrorToast(error);
        this.uploading = false;
        this.fileData = null;
      },
    );
  }

  preview() {
    const mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) === null) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
    };
  }

  openFileInput() {
    this.fileInput.nativeElement.click();
  }
}
