import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[spaceControl]'
})
export class SpaceControlDirective {

  constructor(private ngControl: NgControl) { }

  @HostListener('input', ['$event'])
  onInputChange(event: Event) {
    const input = (event.target as HTMLInputElement).value;
    if (this.ngControl.control) {
      this.ngControl.control.setValue(input.replace(/\s/g, ''));
    }
  }
  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const pasteData = event.clipboardData ? event.clipboardData.getData('text/plain') : '';
    const sanitizedData = pasteData.replace(/\s/g, '');
    document.execCommand('insertText', false, sanitizedData);
  }
}
