import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';
@Directive({
  selector: '[ngModel][upperCase]'

  // selector: 'input[type=text]',
  // selector: '[upperCase]',
  // host: {
  //   '(change)': `ref.nativeElement.value=$event.target.value.toLocaleUpperCase('tr-TR')`,
  // }
})
export class UpperCaseDirective {
  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();
  value: any;

  @HostListener('input', ['$event']) onInputChange($event) {
    this.value = $event.target.value.toUpperCase();
    this.ngModelChange.emit(this.value);
  }
}