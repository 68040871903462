import { Injectable } from '@angular/core';
import { BaseService, HttpClientService } from './base.service';
import { BaseResponseWrapper } from '../models/api-response-wrappers';
import { Observable } from 'rxjs';
import { LedgerVatGroupListResponse, TransactionResponse } from '../models/transaction';
import { Keys, OrderOptions } from '../constants/keys';
import { LedgerDeclarationTransaction } from '../models/ledger';

const Endpoints = {
    Controller: 'edefter',
    //
    GiderBelgeTuru: 'edefter/giderbelgeturu',
    GelirBelgeTuru: 'edefter/gelirbelgeturu',
    //
    AlisTuru: 'edefter/alisturu',
    SatisTuru: 'edefter/satisturu',
    //
    GelirKayitTuru: 'edefter/gelirkayitturu',
    GiderKayitTuru: 'edefter/giderkayitturu',
    //
    GelirKayitAltTuru: 'edefter/gelirkayitaltturu',
    GiderKayitAltTuru: 'edefter/giderkayitaltturu',
    //
    IhracKayitliTeslimKodu: 'edefter/ihrackayitlisatis',
    VergiDairesi: 'edefter/vergidairesi',
    //
    Create: 'edefter/create',
    SendLater: 'edefter/send-later',
    LedgerDeclarationVatGroups: 'edefter/ledger-declaration-vat-groups/{id}',
    LedgerDeclarationVatPost: "edefter/ledger-declaration-vat-group",
    LedgerDeclarationVatPut: "edefter/ledger-declaration-vat-group",
    LedgerDeclarationVatDelete: "edefter/ledger-declaration-vat-group",
    LedgerProductsGet: 'edefter/ledger-declaration-products/{id}',
    LedgerProductsPost: 'edefter/ledger-declaration-product',
    LedgerProductsPut: 'edefter/ledger-declaration-product',
    LedgerProductsDelete: "edefter/ledger-declaration-product/",
    // Update EDefter
    updateTransaction: "edefter/update-ledger-declaration-transaction/{mainUserId}",
    updateVatGroupAuto: 'edefter/calculate-ledger-declaration-vat-groups',
    getAllType: 'edefter/ledger-declaration-types',
    LedgerDeclarationTransactionGet:'edefter/ledger-declaration-transaction/{transactionId}',
    updateLedgerTransactionCompany: 'edefter/update-ledger-declaration-transaction-company/{mainUserId}',
    ledgerDeclarationTransactions: 'edefter/ledger-declaration-transactions',
    bulkCalculateLedgerDeclarationVatGroups: 'edefter/bulk-calculate-ledger-declaration-vat-groups/{mainUserId}?type={type}&isSendELedger={isSendELedger}&status={status}&page={page}&size={size}&startDate={startDate}&endDate={endDate}&sort_by={sort_by}&order_by={order_by}&ledgerDeclarationStatus={ledgerDeclarationStatus}',
    bulkRecordSubtype: 'edefter/bulk-record-subtype/{mainUserId}?type={type}&isSendELedger={isSendELedger}&status={status}&page={page}&size={size}&startDate={startDate}&endDate={endDate}&sort_by={sort_by}&order_by={order_by}&ledgerDeclarationStatus={ledgerDeclarationStatus}',
    createSelectedTransaction: 'edefter/create/{transactionId}',
    wontBeSendEledger: 'edefter/wont-be-send-eLegder/{transactionId}'
};

@Injectable()
export class EDefterService extends BaseService<any, any> {

    constructor(httpClient: HttpClientService) {
        super(httpClient, Endpoints.Controller);
    }

    giderBelgeTuru() {
        const url = this.createUrlWithParams(Endpoints.GiderBelgeTuru);
        return this.client.get(url);
    }

    gelirBelgeTuru() {
        const url = this.createUrlWithParams(Endpoints.GelirBelgeTuru);
        return this.client.get(url);
    }

    alisTuru() {
        const url = this.createUrlWithParams(Endpoints.AlisTuru);
        return this.client.get(url);
    }

    satisTuru() {
        const url = this.createUrlWithParams(Endpoints.SatisTuru);
        return this.client.get(url);
    }

    giderKayitTuru() {
        const url = this.createUrlWithParams(Endpoints.GiderKayitTuru);
        return this.client.get(url);
    }

    gelirKayitTuru() {
        const url = this.createUrlWithParams(Endpoints.GelirKayitTuru);
        return this.client.get(url);
    }

    giderKayitAltTuru() {
        const url = this.createUrlWithParams(Endpoints.GiderKayitAltTuru);
        return this.client.get(url);
    }

    gelirKayitAltTuru() {
        const url = this.createUrlWithParams(Endpoints.GelirKayitAltTuru);
        return this.client.get(url);
    }

    vergiDairesi() {
        const url = this.createUrlWithParams(Endpoints.VergiDairesi);
        return this.client.get(url);
    }

    ihracKayitliTeslimKodu() {
        const url = this.createUrlWithParams(Endpoints.IhracKayitliTeslimKodu);
        return this.client.get(url);
    }

    create(ids) {
        const fullPath = this.createUrlWithParams(Endpoints.Create);
        return this.client.post(fullPath, JSON.stringify(ids));
    }

    sendLater(ids) {
        const fullPath = this.createUrlWithParams(Endpoints.SendLater);
        return this.client.post(fullPath, JSON.stringify(ids));
    }
    ledgerDeclarationGet(requestId: any): Observable<any> {
        const path = this.createUrlWithParams(Endpoints.LedgerDeclarationVatGroups, { id: requestId });
        // const path = this.createUrlWithParams(Endpoints.LedgerDeclarationVatGroups + `${requestId}`);
        return this.client.get<BaseResponseWrapper<any>>(path);
    }
    ledgerDeclarationPost(request: any): Observable<BaseResponseWrapper<any>> {
        const json = {
            ...request
        };
        const path = this.createUrlWithParams(Endpoints.LedgerDeclarationVatPost);
        return this.client.post<BaseResponseWrapper<any>>(path, JSON.stringify(json));
    }
    ledgerDeclarationPut(request: any): Observable<BaseResponseWrapper<any>> {
        const json = {
            ...request
        };
        const path = this.createUrlWithParams(Endpoints.LedgerDeclarationVatPut);
        return this.client.put<BaseResponseWrapper<any>>(path, JSON.stringify(json));
    }
    LedgerDeclarationVatDelete(request: any): Observable<any> {
        const json = {
            ...request
        }
        const fullPath = this.createUrlWithParams(Endpoints.LedgerDeclarationVatDelete);
        return this.client.delete<BaseResponseWrapper<any>>(fullPath, JSON.stringify(json));
    }
    ledgerProductsGet(requestId: any): Observable<any> {
        const path = this.createUrlWithParams(Endpoints.LedgerProductsGet, { id: requestId });
        return this.client.get<BaseResponseWrapper<any>>(path);
    }
    ledgerProductsPost(request: any): Observable<BaseResponseWrapper<any>> {
        const json = {
            ...request
        };
        const path = this.createUrlWithParams(Endpoints.LedgerProductsPost);
        return this.client.post<BaseResponseWrapper<any>>(path, JSON.stringify(json));
    }
    ledgerProductsPut(request: any): Observable<BaseResponseWrapper<any>> {
        const json = {
            ...request
        };
        const path = this.createUrlWithParams(Endpoints.LedgerProductsPut);
        return this.client.put<BaseResponseWrapper<any>>(path, JSON.stringify(json));
    }
    ledgerProductsDelete(_productId: any, _transactionId: any): Observable<any> {
        const fullPath = this.createUrlWithParams(Endpoints.LedgerProductsDelete + `product/${_productId}/transaction/${_transactionId}`);
        return this.client.delete<BaseResponseWrapper<any>>(fullPath);
    }
    updateTransactionRow(request: any , _userId:string): Observable<BaseResponseWrapper<LedgerDeclarationTransaction>> {
        const url = this.createUrlWithParams(Endpoints.updateTransaction, { mainUserId:_userId });
        const json = {
            ...request,
        };
        return this.client.put<BaseResponseWrapper<TransactionResponse>>(url, JSON.stringify(json));
    };
    updateVatgroupAuto(_transactionId: string): Observable<BaseResponseWrapper<LedgerVatGroupListResponse>> {
        const url = this.createUrlWithParams(Endpoints.updateVatGroupAuto);
        const json = {
            transactionId: _transactionId,
        };
        return this.client.post<BaseResponseWrapper<LedgerVatGroupListResponse>>(url, JSON.stringify(json));
    };
    getTypeAll() {
        const url = this.createUrlWithParams(Endpoints.getAllType);
        return this.client.get<BaseResponseWrapper<any>>(url);
    }
    ledgerDeclarationTransactionGet(_transactionId: any): Observable<any> {
        const path = this.createUrlWithParams(Endpoints.LedgerDeclarationTransactionGet, { transactionId: _transactionId });
        // const path = this.createUrlWithParams(Endpoints.LedgerDeclarationVatGroups + `${requestId}`);
        return this.client.get<BaseResponseWrapper<any>>(path);
    }
    updateLedgerTransactionCompany(request: any , _userId:string): Observable<BaseResponseWrapper<LedgerDeclarationTransaction>> {
        const url = this.createUrlWithParams(Endpoints.updateLedgerTransactionCompany, { mainUserId:_userId });
        const json = {
            ...request,
        };
        return this.client.put<BaseResponseWrapper<TransactionResponse>>(url, JSON.stringify(json));
    };
    bulkCalculateLedgerDeclarationVatGroups(
        type: string,
        page: number = 0,
        size: number = 30,
        userId: string,
        isSendELedger: number,
        startDate: string = null,
        endDate: string = null,
        sortBy: string = null,
        order_by: string = OrderOptions.Desc,
        _ledgerDeclarationStatus:number ,
    ): Observable<BaseResponseWrapper<LedgerVatGroupListResponse>> {
        const json = {
            mainUserId:userId,
            type: type,
            page: page,
            size: size,
            isSendELedger: isSendELedger,
            startDate: startDate,
            endDate: endDate,
            sort_by: sortBy,
            order_by: order_by,
            status: 'Completed',
            ledgerDeclarationStatus: _ledgerDeclarationStatus
        };

        const fullPath = this.createUrlWithParams(Endpoints.bulkCalculateLedgerDeclarationVatGroups, json);
        return this.client.post<BaseResponseWrapper<LedgerVatGroupListResponse>>(fullPath);
    }

    bulkRecordSubtype(
        type: string,
        page: number = 0,
        size: number = 30,
        userId: string,
        isSendELedger: number,
        startDate: string = null,
        endDate: string = null,
        sortBy: string = null,
        order_by: string = OrderOptions.Desc,
        _ledgerDeclarationStatus:number ,
    ): Observable<BaseResponseWrapper<LedgerVatGroupListResponse>> {
        const json = {
            mainUserId:userId,
            type: type,
            page: page,
            size: size,
            isSendELedger: isSendELedger,
            startDate: startDate,
            endDate: endDate,
            sort_by: sortBy,
            order_by: order_by,
            status: 'Completed',
            ledgerDeclarationStatus: _ledgerDeclarationStatus
        };

        const fullPath = this.createUrlWithParams(Endpoints.bulkRecordSubtype, json);
        return this.client.post<BaseResponseWrapper<LedgerVatGroupListResponse>>(fullPath);
    }

    createSelectedTransaction(_transactionId: any): Observable<any> {
        const path = this.createUrlWithParams(Endpoints.createSelectedTransaction, { transactionId: _transactionId });
        // const path = this.createUrlWithParams(Endpoints.LedgerDeclarationVatGroups + `${requestId}`);
        return this.client.post<BaseResponseWrapper<any>>(path);
    }

    wontBeSendEledger(_transactionId: any): Observable<any> {
        const path = this.createUrlWithParams(Endpoints.wontBeSendEledger, { transactionId: _transactionId });
        // const path = this.createUrlWithParams(Endpoints.LedgerDeclarationVatGroups + `${requestId}`);
        return this.client.post<BaseResponseWrapper<any>>(path);
    }
}


