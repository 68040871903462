import { DatePipe } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SelectItemCustom } from '../models/select';
import { SelectItem } from 'primeng/api';

@Injectable()
export class HelperService {
    constructor(
        @Inject(LOCALE_ID) private localeId: string,
        @Inject(TranslateService) private translate: TranslateService) {
    }

    map<T>(resource, target: T): T {
        Object.keys(target).forEach(key => target[key] = resource[key]);
        return target;
    }

    pipeDate(date, format = null) {
        if (format === 'dateAndTime') {
            return `${new DatePipe(this.localeId).transform(date, 'longDate')} ${new DatePipe(this.localeId).transform(date, 'shortTime')}`;
        }
        return new DatePipe(this.localeId).transform(date, format ? format : 'longDate');
    }

    getTranslatedEnum(source: any, translationAddress: string, isNumberType: boolean = false, isReversed: boolean = false): SelectItemCustom[] {
        const selectItems: SelectItemCustom[] = [];
        Object.getOwnPropertyNames(source).forEach(x => {
            if (isNumberType) {
                selectItems.push({
                    name: this.translate.instant(`ENUMS.${translationAddress}.${x}`),
                    value: this.translate.instant(`ENUMS.${translationAddress}.${x}`),
                });
            } else {
                if (isReversed) {
                    selectItems.push({
                        name: x,
                        value: this.translate.instant(`ENUMS.${translationAddress}.${x}`),
                    });
                } else {
                    selectItems.push({
                        name: this.translate.instant(`ENUMS.${translationAddress}.${x}`),
                        value: x,
                    });
                }
            }
        });
        return selectItems;
    }

    setDropdownItems(source: any, isStaticData: boolean = false): SelectItem[] {
        const selectItems: SelectItem[] = [];
        source.forEach(x => {
            if (!isStaticData) {
                selectItems.push({
                    label: x.name,
                    value: x.id,
                });
            } else {
                selectItems.push({
                    label: x.name,
                    value: x.value,
                });
            }
        });

        return selectItems;
    }
}
