import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaginatedResponseWrapperOvi } from '../models/api-response-wrappers';
import { GibQuery } from '../models/gib-query';
import { GibDocumentResponse } from '../models/user';
import { BaseService, HttpClientService } from './base.service';

const Endpoints = {
   Controller: 'gib-documents',
   RunQuery: 'gib-documents/run-query',
   GetTaxPeriodDocuments: 'gib-documents?',
   GetTaxPeriodDocumentsExcel: 'gib-documents/excel?'
};

@Injectable()
export class GibDocumentService extends BaseService<any, any> {
   constructor(httpClient: HttpClientService) {
      super(httpClient, Endpoints.Controller);
   }


   getTaxPeriodDocuments(
      taxPeriodStartDate: Date,
      taxPeriodEndDate: Date,
      getUsersWithDoc: boolean,
      selectedDocumentTypeOption: string,
      params,
      page: number = 0,
      size: number = 30,
   ): Observable<PaginatedResponseWrapperOvi<GibDocumentResponse>> {
      const json = {
         taxPeriodStartDate: taxPeriodStartDate.toJSON(),
         taxPeriodEndDate: taxPeriodEndDate.toJSON(),
         getUsersWithDoc: getUsersWithDoc,
         documentType: selectedDocumentTypeOption,
         page: page,
         size: size,
         ...params,
      };
      let fullPath = this.createUrlWithParams(Endpoints.GetTaxPeriodDocuments, json);
      Object.keys(json).forEach(x => {
         if (json[x] !== undefined && json[x] !== null) {
            const qParam = `${x}=${json[x]}&`;
            fullPath += qParam;
         }
      });

      if (fullPath.endsWith('&')) fullPath = fullPath.slice(0, -1);

      return this.client.get<PaginatedResponseWrapperOvi<GibDocumentResponse>>(fullPath);
   }

   getTaxPeriodDocumentsExcel(
      taxPeriodStartDate: Date,
      taxPeriodEndDate: Date,
      selectedDocumentTypeOption: string,
      getUsersWithDoc: boolean,
   ): Observable<any> {
      const json = {
         taxPeriodStartDate: taxPeriodStartDate.toJSON(),
         taxPeriodEndDate: taxPeriodEndDate.toJSON(),
         documentType: selectedDocumentTypeOption,
         getUsersWithDoc: getUsersWithDoc,
      };
      let fullPath = this.createUrlWithParams(Endpoints.GetTaxPeriodDocumentsExcel, json);
      Object.keys(json).forEach(x => {
         if (json[x] !== undefined && json[x] !== null) {
            const qParam = `${x}=${json[x]}&`;
            fullPath += qParam;
         }
      });

      if (fullPath.endsWith('&')) fullPath = fullPath.slice(0, -1);

      return this.client.get<any>(fullPath, true);
   }



   runQuery(request: GibQuery) {
      const fullPath = this.createUrlWithParams(Endpoints.RunQuery);
      return this.client.post<any>(fullPath, JSON.stringify(request));
   }
}