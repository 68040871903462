import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { BaseService, HttpClientService } from './base.service';
import { BaseResponseWrapper, PaginatedResponseWrapperOvi } from '../models/api-response-wrappers';
import { VatAcceptanceRequest, VatAcceptanceResponse, DeclarationApproveResponse, VatAcceptanceStatus } from '../models/vat-acceptance';

const Endpoints = {
   Controller: 'users/me/vat-acceptance',
   GetDeclarationApprove: 'vat-acceptance?',
};

@Injectable()
export class VatAcceptanceService extends BaseService<VatAcceptanceRequest, VatAcceptanceResponse> {

   constructor(httpClient: HttpClientService) {
      super(httpClient, Endpoints.Controller);
   }

   getVatAcceptanceStatus(): Observable<BaseResponseWrapper<VatAcceptanceResponse>> {
      let fullPath = this.createUrlWithParams(Endpoints.Controller);
      return this.client.get(fullPath);
   }

   acceptVat() {
      let fullPath = this.createUrlWithParams(Endpoints.Controller);
      return this.client.post(fullPath);
   }
   getDeclarationApprove(
      taxPeriodDate: Date,
      vatAcceptanceStatus: VatAcceptanceStatus,
      params,
      page: number = 0,
      size: number = 30,
   ): Observable<PaginatedResponseWrapperOvi<DeclarationApproveResponse>> {
      const json = {
         taxPeriodDate: taxPeriodDate.toJSON(),
         vatAcceptanceStatus: vatAcceptanceStatus,
         page: page,
         size: size,
         ...params,
      };
      let fullPath = this.createUrlWithParams(Endpoints.GetDeclarationApprove, json);
      Object.keys(json).forEach(x => {
         if (json[x] !== undefined && json[x] !== null) {
            const qParam = `${x}=${json[x]}&`;
            fullPath += qParam;
         }
      });

      if (fullPath.endsWith('&')) fullPath = fullPath.slice(0, -1);
      return this.client.get<PaginatedResponseWrapperOvi<DeclarationApproveResponse>>(fullPath);
   }
}
