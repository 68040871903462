import { Injectable } from '@angular/core';
import { BaseService, HttpClientService } from './base.service';
import { BaseResponseWrapper } from '../models/api-response-wrappers';
import { AccountingPartyInfo } from '../models/accounting-party/accounting-party-info';

const Endpoints = {
    Controller: 'accounting-party',
    Info: 'accounting-party/info/{taxNo}'
};

@Injectable()
export class AccountingPartyService extends BaseService<any, AccountingPartyInfo> {

    constructor(httpClient: HttpClientService) {
        super(httpClient, Endpoints.Controller);
    }

    async infoAsync(taxNo: string): Promise<AccountingPartyInfo> {
        const fullPath = (this.createUrlWithParams(Endpoints.Info, { taxNo }));
        const response: BaseResponseWrapper<AccountingPartyInfo> = await this.client.get<BaseResponseWrapper<AccountingPartyInfo>>(fullPath).toPromise();
        return response.data;
    }
}
