import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { TransactionSummaryResponse } from '../models/report';
import { BaseService, HttpClientService } from './base.service';
import { SharedService } from './shared.service';

const Endpoints = {
    Controller: '',
    TransactionSummary: 'users/{id}/transactions/summary?startDate={startDate}&endDate={endDate}',
};

@Injectable()
export class ReportService extends BaseService<any, any> {

    constructor(httpClient: HttpClientService, private sharedService: SharedService) {
        super(httpClient, Endpoints.Controller);
    }

    getTransactionSummary(
        type: string,
        startDate: any,
        endDate: any,
    ): Observable<TransactionSummaryResponse> {
        const userId = this.sharedService.userId;
        if (!userId) {
            return;
        }
        const json = {
            id: userId,
            startDate: startDate.startOf('day').toISOString(),
            endDate: endDate.endOf('day').toISOString(),
        };

        const fullPath = this.createUrlWithParams(Endpoints.TransactionSummary, json);
        return this.client.get<TransactionSummaryResponse>(fullPath);
    }


}
