import { InvoiceProfileIdEnum } from '../../../enums/invoice-profile-id-enum';
import { InvoiceTypeCodeEnum } from '../../../enums/invoice-type-code-enum';
import { TransactionStatusEnum } from '../../../enums/transaction-status-enum';
import { ReturnTransaction } from './accounting/return-transaction';
import { IResponse } from './api-response-wrappers';
import { CompanyResponse } from './company';
import { UserResponse } from './user';

export enum VatType {
    Percent = 'Percent',
    Aggregate = 'Aggregate',
}

// export enum TransactionStatusEnum {
//     CanceledByAdmin = 'CanceledByAdmin',
//     Canceled = 'Canceled',
//     Pending = 'Pending',
//     InProgress = 'InProgress',
//     Completed = 'Completed',
//     Delayed = 'Delayed',
//     Unanswered = 'Unanswered',
//     Returned = 'Returned'
// }

export enum TransactionStatusLabel {
    CanceledByAdmin = 'İptal (Yönetici)',
    Canceled = 'İptal',
    Pending = 'Beklemede',
    InProgress = 'İşlem Devam Ediyor',
    Completed = 'Tamamlandı',
    Delayed = 'Gecikti',
    Unanswered = 'Cevaplanmadı',
    Returned = 'İade',
    Rejected = 'Reddedildi',
    Error = 'Teknik Hata'
}

export enum EnumTransactionProfile {
    TEMELFATURA = 0,
    TICARIFATURA = 1,
    IHRACAT = 2,
    YOLCUBERABERFATURA = 3,
    EARSIVFATURA = 4,
    DIGER = 99
}


export enum TransactionType {
    Income = 'Income',
    Expense = 'Expense',
}

export enum CurrencyType {
    TRY = 'TRY',
    EUR = 'EUR',
    GBP = 'GBP',
    USD = 'USD',
    AUD = 'AUD',
    CAD = 'CAD',
    JPY = 'JPY',
    CNY = 'CNY',
    INR = 'INR',
}

export enum VatRate {
    twenty = 20,
    Eighteen = 18,
    ten = 10,
    Eight = 8,
    One = 1,
    Zero = 0,
}

export enum TransactionAcceptanceStatus {
    Rejected = -1,
    Pending,
    Accepted
}

export class Product {
    name: string = '';
    unitPrice: number = 0;
    vat: number = VatRate.Eighteen;
    vatType: string = VatType.Percent;
    sct: number = 0;
    sctType: string = VatType.Percent;
    amount: number = 0;
    withholdingRate: number = null;
    withholdingAmount: number = 0;
    withholdingTaxDefinitionCode: string = null;
    withholdingTaxDefinitionId: number = null;
}

export class TransactionProduct {
    quantity: number = 1;
    withholdingRate: number = null;
    withholdingTaxDefinitionId = null;
    withholdingTaxDefinitionCode = null;
    product: Product = new Product();
    index: number = 0;
    hasWithholding: boolean = false;
}

export class TransactionRequest {
    title: string = null;
    invoiceNo: string = null;
    companyId: string = null;
    customerUserId: string = null;
    amount: number = 0;
    totalAmount: number = 0;
    totalVat: number = 0;
    vat: number = null;
    vatType: string = VatType.Percent;
    totalSct: number = 0;
    onDate: Date = new Date();
    InvoiceDate: Date = new Date();
    status: string = TransactionStatusEnum.Pending;
    type: TransactionType = null;
    currency: string = CurrencyType.TRY;
    transactionProducts: TransactionProduct[] = [];
    isImported: boolean = false;
    hasDocument: boolean = false;
    invoiceUUID: string = null;
    hasProducts: boolean = false;
    withholding: boolean = false;
    withholdingRate: any = null;
    dontSendNotification: boolean = false;
    belgeKayitTuruKodu: string = null;
    belgeKayitAltTuruKodu: string = null;
    belgeTuruKodu: string = null;
    alisSatisTuru: string = null;
    ihracKayitliTeslimKodu: string = null;
    // isSentEDefter: boolean = false;
    isSendELedger: number;
    isCompleted = false;
    //invoiceScenario: number = 1;
    profileId: InvoiceProfileIdEnum = InvoiceProfileIdEnum.TICARIFATURA;
    companyName: string = null;
    dueAmount: number = 0;
    withholdingTaxDefinitionCode: string = null;
    withholdingTaxDefinitionId: number = null;
    withholdingAmount: number = 0;
    invoiceType: InvoiceTypeCodeEnum = InvoiceTypeCodeEnum.SATIS;
    vatTaxExemptionId: number | null = null;
    returnTransaction: ReturnTransaction | null = null;
    doNotCreateTransactionProductsAutomatically: boolean = false;
}

export class AdvisorTransactionRequest extends TransactionRequest {
    isLoading = false;
    isInvoiceSuccessfullyCreated: boolean;
    isTransactionSuccessfullyCreated: boolean;
    exception: string;
    transactionResponse: TransactionResponse;
}

export class TransactionStatusUpdateRequest {
    status: TransactionStatusEnum;
    invoiceStatusMessage: string;
}

export class TransactionAcceptanceStatusRequest {
    acceptanceStatus: TransactionAcceptanceStatus;
}


export interface TransactionResponse extends IResponse {
    transactionProducts: TransactionProduct[];
    company: CompanyResponse;
    title: string;
    invoiceNo: string;
    imageUrl: string;
    totalAmount: number;
    totalVat: number;
    totalSct: number;
    vat: number;
    vatType: string;
    sct: number;
    sctType: string;
    onDate: Date;
    InvoiceDate: Date;
    status: string;
    type: string;
    currency: string;
    isImported: boolean;
    invoiceUUID: string;
    invoiceStatusMessage: string;
    hasProducts: boolean;
    withholding: boolean;
    withholdingRate: number;
    hasDocument: boolean;

    isCreatedByAdmin: boolean;

    acceptanceStatus: TransactionAcceptanceStatus;

    owner: UserResponse;
    belgeKayitTuruKodu: string;
    belgeKayitAltTuruKodu: string;
    belgeTuruKodu: string;
    alisSatisTuru: string;
    ihracKayitliTeslimKodu: string;
    // isSentEDefter: boolean;
    isSendELedger: number;
    profileId: InvoiceProfileIdEnum;
    companyId: string;
    invoiceType: InvoiceTypeCodeEnum;
    vatTaxExemptionId: number | null;
    returnTransaction: ReturnTransaction | null;
    transactionOcrResult: TransactionOcrResult
    taxNo:string;
    eDefterTransactionResultMessage:string | null;
}

export class TransactionOcrResult {
    transactionId: string;
    isSuccess: boolean;
    message: string;
}

export interface OCRResponse extends IResponse {
    totalVat: number;
    totalAmount: number;
    address: string;
    companyName: string;
    taxNo: string;
    receiptNo: string;
    date: Date;
}


export class TransactionSearchParams {
    title?: string;
    invoiceNo?: string;

    ownerCompanyName?: string;
    ownerAgentName?: string;

    companyCompanyName?: string;
    ownerId?: string;

    isFromExcel?: boolean;
    isImported?: boolean;
    withholding?: boolean;

    type?: TransactionType;
    status?: TransactionStatusEnum;

    createDateStart?: Date;
    createDateEnd?: Date;

    onDateStart?: Date;
    onDateEnd?: Date;

    invoiceDateStart?: Date;
    invoiceDateEnd?: Date;
    // isSentEDefter?: boolean;
    isSendELedger: number;

    ownerTaxNo?: string = null;
}

export class InvoiceAnswerRequest {
    answer: ServiceAnswer;
    rejectNote?: string;
}

export enum ServiceAnswer {
    Accepted = 'Accepted',
    Rejected = 'Rejected'
}

export class LegetStatementSubListResponse {
    vatRate: number;
    base: number;
    vat: number;
    count: number;
}

export class LedgerProductRequest {
    id: any;
    transactionId: string;
    userId: string;
    name: string;
    unitPrice: number;
    vat: number;
    quantity: number;
    vatRate: number;
    vatType: VatType;
    withholdingRate: null;
    withholdingAmount: null;
    withholdingTaxDefinitionId: null;
}


export class LedgerRequest {
    transactionId: string;
    unitPrice: number;
    vatRate: number;
    comment: string;
}
export class LedgerVatGroupListResponse {
    id: string;
    transactionId: string;
    buySellType: number;
    documentRecordSubTypeCode: number;
    documentRecordTypeCode: number;
    unitPrice: number;
    vatRate: number;
    vat: number;
    totalAmount: number;
    vatGroupResult: [{
        transactionId: string;
        isSuccess: boolean;
        message: any;
    }]
}
export class LedgerVatGroupListRequest {
    transactionId: string | null;
    buySellType: number | null;
    documentRecordSubTypeCode: number | null;
    documentRecordTypeCode: number | null;
    documentTypeCode: number | null;
    recordType: number | null;
    unitPrice: number | null;
    vatRate: number | null;
    vat: number | null;
}
export enum RecordType {
    incomeRecords = 1,
    expenseRecords = 2
}
